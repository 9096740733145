





















































































import { Component, Vue, Prop } from "vue-property-decorator";
import { CalendarDay } from "@/components/Cards/CalendarDay";
import { Usuario } from "@/shared/dtos/usuario";

@Component({
  components: {
    MaterialOnlyOneMouht: () =>
      import("@/components/Cards/MaterialOnlyOneMouht.vue"),
  },
})
export default class CalendarioSeleccionableFestivos extends Vue {
  @Prop() value!: CalendarDay[];
  @Prop() usuario!: Usuario;
  @Prop() idUsuario!: number;
  @Prop() idColorTipoFestivos!: any[];
  @Prop() festivos!: { text: string; color: string; id: number }[];

  public YearSync = new Date().getFullYear();
  public CalendarDaysSelected: CalendarDay[] = [];
  public CalendarDaysInMouth: {
    [year: number]: { [month: number]: CalendarDay[] };
  } = {};

  /*public festivos_convenio = [
    { text: "Festivo", color: "#e57373", id: 54 },
    { text: "Festivo especial", color: "#f57c00", id: 309 },
  ];

  public festivos_empleado = [
    { text: "Vacaciones", color: "yellow", id: 53 },
    { text: "Día Libre", color: "#8FCE09", id: 311 },
    { text: "Asuntos propios", color: "#4db6ac", id: 312 }
  ];*/

  public color_seleccionado = "";
  public modo_seleccionar = false;
  public DiasSeleccionadosAccion: CalendarDay[] = [];

  created() {
    this.CalendarDaysSelected = this.value;
    // Reconstrucción de CalendarDaysInMouth a partir de los datos de la base de datos
    this.value.forEach((day: CalendarDay) => {
      // Asegurar que el año y el mes existen en el almacenamiento
      if (!this.CalendarDaysInMouth[day.Year]) {
        this.CalendarDaysInMouth[day.Year] = {};
      }
      if (!this.CalendarDaysInMouth[day.Year][day.Mouth]) {
        this.CalendarDaysInMouth[day.Year][day.Mouth] = [];
      }

      // Agregar el día al mes y año correspondiente
      this.CalendarDaysInMouth[day.Year][day.Mouth].push(day);
    });

    this.FilterDataYear();
  }

  public get festivo_seleccionado() {
    return this.festivos.find((x) => x.color === this.color_seleccionado);
  }

  public SeleccionadosFestivos(color: string) {
    this.CalendarDaysSelected.filter((x) => x.Color === color).length;
  }

  // Cambiar año
  public changeYear(amount: number) {
    this.YearSync += amount;
    this.FilterDataYear();
  }

  // Actualizar selección de días
  public UpdateHandler() {
    if (!this.CalendarDaysInMouth[this.YearSync]) {
      this.CalendarDaysInMouth[this.YearSync] = {};
    }

    // Fusionar los días de todos los años y meses
    this.CalendarDaysSelected = Object.values(this.CalendarDaysInMouth)
      .map((year) => Object.values(year).flat())
      .flat();

    // Emitir evento con los días seleccionados de todos los años
    this.$emit("input", this.CalendarDaysSelected);
  }

  // Selección de color
  public SeleccionarColor(tipo: any) {
    this.color_seleccionado =
      this.color_seleccionado === tipo.color ? "" : tipo.color;
  }
  updateSelectedDay(day: CalendarDay) {
    this.$emit("dia-seleccionado", day);
  }

  public updateSelectedDays(mes: number, dias: CalendarDay[]) {
    // Asegurar que el año y el mes existan en el almacenamiento
    if (!this.CalendarDaysInMouth[this.YearSync]) {
      this.CalendarDaysInMouth[this.YearSync] = {};
    }

    // Fusionar los días de todos los años y meses
    this.CalendarDaysSelected = Object.values(this.CalendarDaysInMouth)
      .map((year) => Object.values(year).flat())
      .flat();

    // Emitir evento con los días seleccionados
    this.$emit("dias-seleccionados", this.CalendarDaysSelected);
  }

  public FilterDataYear() {
    // Asegurar que el año tenga su propio almacenamiento
    if (!this.CalendarDaysInMouth[this.YearSync]) {
      this.CalendarDaysInMouth[this.YearSync] = {};
    }

    // Fusionar los días de todos los años y meses
    this.CalendarDaysSelected = Object.values(this.CalendarDaysInMouth)
      .map((year) => Object.values(year).flat())
      .flat();
  }
}
