






























import { Component, Vue } from "vue-property-decorator";
import CalendarioSeleccionableFestivos from "@/views/Fichaje/CalendarioSeleccionableFestivos.vue";
import tipos_fichajeModule from "@/store/modules/tipos_fichaje-module";
import { CalendarDay } from "@/components/Cards/CalendarDay";
import calendario_laboralModule from "@/store/modules/calendario_laboral-module";
import { calendario_laboral } from "@/shared/dtos/calendario_laboral";
import { calendario_laboral_dia } from "@/shared/dtos/calendario_laboral_dia";
import { MessageService } from "@/shared/services/message-service";
import { UtilsNumber } from "@/utils/utils-number";

@Component({
  components: {
    CalendarioSeleccionableFestivos,
  },
})
export default class CalendarioLaboral extends Vue {
  public CalendarDaysSelected: CalendarDay[] = [];
  public calendario = new calendario_laboral();
  public preparado = false;
  async created() {
    await tipos_fichajeModule.gettipos_fichajes();
    await calendario_laboralModule.getcalendario_laborales();

    calendario_laboralModule.calendario_laborales.forEach((cale) => {
      this.calendario = cale;
      this.calendario.dias_festivos.forEach((dia) => {
        var date = new Date(dia.fecha);
        this.CalendarDaysSelected.push(
          new CalendarDay({
            CompleteDate: date,
            Color: tipos_fichajeModule.tipos_fichajes.find(
              (x) => x.id === dia.id_tipo_fichaje
            )!.color,
            activo: true,
            Mouth: date.getMonth() + 1,
            Year: date.getFullYear(),
            Day: date.getDate(),
            CssClass: "active noselect",
            anomesdia: `${date.getFullYear()}${UtilsNumber.formatNumberWhitZero(
              date.getMonth() + 1
            )}${UtilsNumber.formatNumberWhitZero(date.getDate())}`,
          })
        );
      });
    });
    this.preparado = true;
  }

  get tipos_fichajes() {
    return tipos_fichajeModule.tipos_fichajes
      .filter((x) => x.festivos_convenio)
      .map((x) => {
        return { id: x.id, text: x.nombre, color: x.color };
      });
  }
  public diasSeleccionados(DaysSelected: CalendarDay[]) {
    this.CalendarDaysSelected = DaysSelected;
  }
  // Método para guardar los cambios del calendario
  public guardarCalendario() {
    var dias_festivos: calendario_laboral_dia[] = [];
    const uniqueDays = new Set();
    for (let i = 0; i < this.CalendarDaysSelected.length; i++) {
      const dayKey = this.CalendarDaysSelected[i].anomesdia;
      if (!uniqueDays.has(dayKey)) {
      uniqueDays.add(dayKey);
      dias_festivos.push(
        new calendario_laboral_dia({
        id: 0,
        fecha: this.CalendarDaysSelected[i].CompleteDate,
        id_calendario_laboral: 0,
        id_tipo_fichaje: this.tipos_fichajes.find(
          (x) => x.color === this.CalendarDaysSelected[i].Color
        )!.id,
        })
      );
      }
    }
    this.calendario.dias_festivos = dias_festivos;
    calendario_laboralModule
      .guardarcalendario_laboral(this.calendario)
      .then(() => {
        MessageService.toastsuccesful("Calendario Laboral Guardado");
        this.$router.back();
      });
  }
}
